import React from 'react'
import { navigate } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image'

import {
    center,
} from '../components/layout.module.css'

const SsoMap = () => (
  <div>
    <button onClick={() => navigate(-1)}>Go Back</button>
    <StaticImage className={center} style={{height:'90vh'}} objectFit="contain" src="../images/healthcenters-map.jpg" alt="Map of Massachusetts listing out healthcare centers: Hilltown Community Health Center, Community Health Center of Franklin County, Holyoke Health, Family Health Center of Worcester, Edward M. Kennedy Community Health Center, Lynn Community Health Center, North Shore Community Health, Charles River Community Health, Fenway Health, East Boston Neighborhood Health Center, New Health, Upham's Corner Health Center, The Dimock Center, Brockton Neighborhood Health Center, Community Health Connections, Springfield Health Services for the Homeless, Island Health Care, Kronos Health"></StaticImage>
  </div>
)

export default SsoMap